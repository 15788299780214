


















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { isEmpty, noop } from 'lodash';
import * as localStorage from '../localStorage';

@Component
export default class WelcomePage extends Vue {

  isEmpty = isEmpty;
  playerName = localStorage.getUserId();

  sendName() {
    if (!isEmpty(this.playerName)) {
      this.$emit('newPlayer', this.playerName);
      localStorage.setUserId(this.playerName);
    }
  }
}
