
import axios from 'axios';
import { urlprefix } from '../../../common/src/settings';
import { UserProfileDto } from '../../../server/src/userProfile';
import { Device } from 'spotify-web-api-ts/types/types/SpotifyObjects';
import { SetSpotifyDeviceDto } from '../../../server/src/interfaces';

const apiAxios = axios.create();

export const getUserProfile = async (userId: string): Promise<UserProfileDto> => apiAxios.get(`${urlprefix}/userProfile?userId=${userId}`).then(result => result.data);
export const putUserProfile = async (userId: string, profile: UserProfileDto): Promise<void> => { await apiAxios.put(`${urlprefix}/userProfile?userId=${userId}`, profile);}
export const getSpotifyDevices = async (userId: string): Promise<Device[]> => apiAxios.get(`${urlprefix}/getSpotifyDevices?userId=${userId}`).then(result => result.data);

export const getGenreSeeds = async (accessToken: string): Promise<any> => apiAxios.get('https://api.spotify.com/v1/recommendations/available-genre-seeds', { 
  headers: {
    'Authorization': `Bearer ${accessToken}`
  }
}).then(result => result.data);

export const setSpotifyDevice = async (userId: string, spotifyDevice: string): Promise<void> => { 
  const body: SetSpotifyDeviceDto = {
    spotifyDevice
  };

  await apiAxios.post(`${urlprefix}/setSpotifyDevice?userId=${userId}`, body);
}


