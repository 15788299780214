













































import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import store from '../store';
import { PlayerStat } from '../../../../server/src/interfaces'

@Component
export default class Players extends Vue {

  get players() {
    const players = store.state.playerstats as PlayerStat[];

    return players.sort( (player1, player2) => {
      if(this.roundIsFinished) {
        return player2.score - player1.score;
      } else if(this.showPoints) {
        return player2.points - player1.points;
      }
      return player2.roundScore - player1.roundScore;
    })
  }

  get roundIsFinished() {
    return this.$store.getters.roundState && this.$store.getters.roundState.state=="finished";
  }  

  get showPoints() {
    return this.$store.getters.showPoints;
  }
}
