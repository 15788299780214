import { Song } from "./createRound";
export interface PlayerStat {
  player: string,
  score: number,
  roundScore: number,
  answered: 'correct' | 'incorrect' | 'notyet'
  points: number,
}
export interface GameData {
  playerstat: PlayerStat[],
}

export interface RoundState {
  roundId: string,
  state: 'starting' | 'running' | 'finished'
}

export type QuestionState = 'showAlternatives' | 'answered' | 'showScore';

/**
 * Answer sent over the wire from client to server
 */
export interface SendAnswerDto {
  answer: string; // songURI
  quizId: string;
  roundId: string;
  userId: string; // playerName
}

/**
 * JoinQuiz from client to server
 */
export interface SendJoinQuizDto {
  subscribeToGame: string;
  userId: string;
}

export interface SendJoinQuizDto {
  subscribeToGame: string;
  userId: string;
}

export interface SetSpotifyDeviceDto {
  spotifyDevice: string
}
export interface SetSpotifyCodeDto {
  userId: string,
  spotifyCode: string
}

export interface SendSetQuestionDto {
  event: 'setQuestion',
  data: Song[]
}

export interface SendReceivedAnswerDto {
  event: 'playerAnswered',
  userId: string,
  answered: 'correct' | 'incorrect',
  points: number
}

export interface SendGameUpdatedDto {
  updated: string; // quiz id
  gameData: GameData;
}

export interface SendRoundUpdatedDto {
  roundState: RoundState
}

export type KnownServerToClientDto = SendGameUpdatedDto | SendReceivedAnswerDto | SendSetQuestionDto | SendRoundUpdatedDto;

export const isSendRoundUpdatedDto = (obj: unknown): obj is SendRoundUpdatedDto => {
  if (!isObject(obj)) return false;
  const probablySendRoundUpdated = obj as Partial<SendRoundUpdatedDto>;
  return probablySendRoundUpdated.roundState !== undefined;
}


export const isSendGameUpdatedDto = (obj: unknown): obj is SendGameUpdatedDto => {
  if (!isObject(obj)) return false;
  const probablySendGameUpdated = obj as Partial<SendGameUpdatedDto>;
  return probablySendGameUpdated.updated !== undefined && probablySendGameUpdated.gameData !== undefined;
}

export const isSendReceivedAnswerDto = (obj: unknown): obj is SendReceivedAnswerDto => {
  if (!isObject(obj)) return false;
  const probablyReceivedAnswerDto = obj as Partial<SendReceivedAnswerDto>;
  return probablyReceivedAnswerDto.event === 'playerAnswered' && probablyReceivedAnswerDto.userId !== undefined && probablyReceivedAnswerDto.answered !== undefined;
}

export const isSetQuestionDto = (obj: unknown): obj is SendSetQuestionDto => {
  if (!isObject(obj)) return false;
  const probablySetQuestionDto = obj as Partial<SendSetQuestionDto>;
  return probablySetQuestionDto.event === 'setQuestion' && probablySetQuestionDto.data !== undefined && Array.isArray(probablySetQuestionDto.data);
}

export const isSendAnswerDto = (obj: unknown): obj is SendAnswerDto => {
  if (!isObject(obj)) return false;
  const probablyAnswerDto = obj as Partial<SendAnswerDto>;
  return probablyAnswerDto.answer !== undefined && probablyAnswerDto.quizId !== undefined && probablyAnswerDto.userId !== undefined;
}

export const isSendJoinQuizDto = (obj: unknown): obj is SendJoinQuizDto => {
  if (!isObject(obj)) return false;
  const probablySendJoinQuizDto = obj as Partial<SendJoinQuizDto>;
  return probablySendJoinQuizDto.userId !== undefined && probablySendJoinQuizDto.subscribeToGame !== undefined;
}

const isObject = (obj: unknown): obj is Record<string, unknown> => {
  if (!obj) return false;
  if (typeof obj !== 'object') return false;
  if (obj === null) return false;
  if (obj === undefined) return false;
  return true;
};
