import Vuex from 'vuex';
import Vue from 'vue';
import * as settings from '../../../common/src/settings';
import { isSendGameUpdatedDto, isSendReceivedAnswerDto, isSendRoundUpdatedDto, isSetQuestionDto, PlayerStat, RoundState, SendAnswerDto, SendJoinQuizDto } from '../../../server/src/interfaces'
import axios from 'axios';
import { Song } from '../../../server/src/createRound';
import * as api from './api';
import { Device } from 'spotify-web-api-ts/types/types/SpotifyObjects';
import { UserProfileDto } from '../../../server/src/userProfile';
import { invariant } from './model/invariant';
import { CreateRound } from './model/interfaces';
import { QuestionState } from '../../../server/src/interfaces';

const apiAxios = axios.create();

Vue.use(Vuex);

const state = {
  currentUserId: '',
  playerstats: [] as PlayerStat[],
  quizId: null as string | null,
  spotifyAccessToken: "",
  spotifyDevice: "",
  alternatives: [] as Song[],
  selectedAnswer: "",
  spotifyDevices: [] as Device[],
  roundState: null as RoundState | null,
  isHost: false,
  readyForNextSong: true,
  questionState: 'showScore' as QuestionState,
  questionStartTime: 0, // Timestamp for start of question
  questionProgress: 0,
  showPoints: false, // Deciding if we show mid-round points
};

export type State = typeof state;

function send(message: any) {
    Vue.prototype.$socket.send(message);
}

const store = new Vuex.Store({
  state,
  mutations: {
    SOCKET_ONOPEN(state, event)  {
      Vue.prototype.$socket = event.currentTarget;
      console.log("SOCKET_ONOPEN");
      setInterval( () => {
          send(JSON.stringify({heartbeat: true}));
      }, 8*60000); // Every 8 minutes
    },
    SOCKET_ONCLOSE(state, event)  {
      console.log("SOCKET_ONCLOSE");
    },
    SOCKET_ONERROR(state, event)  {
      console.log("SOCKET_ONERROR");
      console.error(state, event);
    },
    async SOCKET_ONMESSAGE(state, message) {
      console.log("SOCKET_ONMESSAGE");
      console.log(message);

      const messageObj: unknown = JSON.parse(message.data);

      if (isSendGameUpdatedDto(messageObj)) { // Todo: check if it's our game
        state.playerstats = messageObj.gameData.playerstat 
      } else if (isSetQuestionDto(messageObj)) {
        state.alternatives = messageObj.data;
        state.selectedAnswer = '';
        if(state.alternatives.length>0) { // This is when a new question is to be shown
          state.questionState = 'showAlternatives';
          state.questionStartTime = new Date().getTime();
          state.showPoints = true;
          let interval = setInterval( ()=> {
            state.questionProgress = new Date().getTime() - state.questionStartTime;
            if(state.questionProgress > 10000) {
              clearInterval(interval);
              state.questionProgress = 0;
            }
          }
          );
        } else {
          state.questionState = 'showScore';
          state.showPoints = false;
        }
      } else if (isSendRoundUpdatedDto(messageObj)) {
        state.roundState = messageObj.roundState;
      } else if (isSendReceivedAnswerDto(messageObj)) {
        state.playerstats.forEach( (stat) => {
          console.log("Checking: " + stat.player + " vs " + messageObj.userId);
          if(stat.player == messageObj.userId) {
            console.log("Player: " + stat.player + "answered: " + messageObj.answered);
            stat.answered = messageObj.answered;
            stat.points = messageObj.points;
            stat.roundScore += (stat.points as number/2.0); // VERY UGLY HACK!
          }
        })
      }
    },
    SOCKET_RECONNECT(state, count) {
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
    },
    setGameData(state, robj) {
      state.playerstats = robj.playerstat;
    },
    setCurrentQuiz(state, quizId) {
      state.quizId = quizId;
    },
    setCurrentRound(state, roundId) {
//      state.roundId = roundId;
    },
    setPlayerName(state, name) {
      console.log("Setting player" + name);
      state.currentUserId = name;
    },
    setSpotifyAccessToken(state, access_token: string) {
      state.spotifyAccessToken = access_token;
    },
    setSelectedAnswer(state, selectedAnswer: string) {
      state.selectedAnswer = selectedAnswer;
    },
    setDevices(state, devices: Device[]) {
      state.spotifyDevices = devices;
    },
    setIsHost(state, value) {
      state.isHost = value;
    },
    setUserProfile(state, userProfile: UserProfileDto) {
      console.log("Setting user profile",userProfile);
      state.spotifyAccessToken = userProfile.spotifyToken;
      state.spotifyDevice = userProfile.spotifyDevice;
      console.dir(state);
    },
    setSpotifyDevice(state, id: string) {
      state.spotifyDevice = id;
    },
    setReadyForNextSong(state, value: boolean) {
      state.readyForNextSong = value;
    },
    setQuestionState(state, qs: QuestionState) {
      state.questionState = qs;
    }
  }, 
  getters: {
    currentUserId: state => state.currentUserId,
    playerstats: state => state.playerstats,
    quizId: state => state.quizId,
    roundId: state => { return state.roundState && state.roundState.roundId || null },
    spotifyAccessToken: state => state.spotifyAccessToken,
    alternatives: state => state.alternatives,
    selectedAnswer: state => state.selectedAnswer,
    spotifyDevices: state => state.spotifyDevices,
    roundState: state => state.roundState,
    isHost: state => state.isHost, 
    readyForNextSong: state => state.readyForNextSong,
    questionState: state => state.questionState,
    selectedSpotifyDevice: state => {
      return state.spotifyDevices.find(device => device.id === state.spotifyDevice) || null;
    },
    questionProgress: state => state.questionProgress,
    showPoints: state => state.showPoints
  },
  actions: {
    loadUserProfile: async context => {
      console.log("loadUserProfile");
      try {
        await api.getUserProfile(context.state.currentUserId); // Will throw on 404 - TODO: add proper error handling
      } catch (error) {
        const userProfile: UserProfileDto = {
          spotifyDevice: '',
          spotifyToken: ''
        }
        await api.putUserProfile(context.state.currentUserId, userProfile)
        context.commit('setUserProfile', userProfile);
      }

      try {
        const profile = await api.getUserProfile(store.state.currentUserId); 
        context.commit('setUserProfile', profile);
        context.dispatch('loadDevices');
      } catch (err) {
        // Profile probably doesn't exist, let's do nothing.
      }
    },
    loadDevices: async context => {
      console.log("Load devices");
      const devices = await api.getSpotifyDevices(context.state.currentUserId);
      context.commit('setDevices', devices);
    },
    setPlayerName: async (context, name) => {
      console.log("setPlayerName");
      store.commit('setPlayerName', name);
    },
    createQuiz: async (context) => {
      return new Promise((resolve, reject) => apiAxios.post(settings.urlprefix+"/createQuiz", {
          owner: context.state.currentUserId
        }).then(result => {
          console.log("Dispatching set current");
          store.commit('setCurrentQuiz', result.data.quizId); 
          store.dispatch('joinQuiz', result.data.quizId); 
          send(JSON.stringify({subscribeToGame: result.data.quizId, userId: context.state.currentUserId}));
          resolve(true);
        }).catch(err => reject(err)));
    },
    createRound: async (context, quizSettings: CreateRound) => {
      return new Promise((resolve, reject) => apiAxios.post(settings.urlprefix+"/createRound", {
          owner: context.state.currentUserId,
          genre: quizSettings.genre,
          choiceCaption: quizSettings.choiceCaption,
          quizId: context.state.quizId,
          releaseDecade: quizSettings.releaseDecade
        }).then(result => {
//          store.commit('setCurrentRound', result.data.roundId); 
          send(JSON.stringify({subscribeToGame: result.data.quizId, userId: context.state.currentUserId}));
          resolve(true);
        }).catch(err => reject(err)));
      },
      joinQuiz: async (context, quizId: string) => {
        const sendDto: SendJoinQuizDto = {
          subscribeToGame: quizId, 
          userId: context.state.currentUserId
        }
        send(JSON.stringify(sendDto));
        apiAxios.post(settings.urlprefix+"/joinQuiz", {
          userId: context.state.currentUserId,
          quizId: quizId
        }).then(result => {
          store.commit('setCurrentQuiz', quizId); 
//          store.commit('setCurrentRound', result.data.roundId); 
        });
      },
      updateGameData: async (context) => {
        const r = await fetch(settings.urlprefix+"/getGameData?gameid="+context.state.quizId);
        const robj = await r.json();
        context.commit('setGameData', robj);
      },
      nextSong: async (context) => {
        context.commit('setReadyForNextSong', false);
        const next = await apiAxios.post(settings.urlprefix+"/nextSong", {
          owner: context.state.currentUserId,
          userId: context.state.currentUserId,
          roundId: context.state.roundState && context.state.roundState.roundId,
          quizId: context.state.quizId
        });
        if(next.status==204) {
//          store.commit('setCurrentRound', null);
        }
        setTimeout(async () => {
          await apiAxios.post(settings.urlprefix+"/showPoints", {
            owner: context.state.currentUserId,
            userId: context.state.currentUserId,
            quizId: context.state.quizId,
            roundId: context.state.roundState?.roundId
          });  
          context.commit('setReadyForNextSong', true);
        }, 10000);
      },
      selectSong: async ({ state }, songUri: string) => {
        state.questionState = 'showScore';
        store.commit('setSelectedAnswer', songUri);
        invariant(state.quizId, 'Expected quiz ID before sending answer');
        invariant(state.roundState && state.roundState.roundId, 'Expected round ID before sending answer');
        const sendAnswer: SendAnswerDto = {
          answer: songUri,
          quizId: state.quizId,
          roundId: state.roundState && state.roundState.roundId,
          userId: state.currentUserId
        }
        send(JSON.stringify(sendAnswer));
      },
      setSpotifyDevice: async (context, deviceId: string) => {
        await api.setSpotifyDevice(store.state.currentUserId, deviceId);
        store.commit('setSpotifyDevice', deviceId);
      }
    }
  }
); 

export default store;
