
















































import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import store from '../store';
import { SpotifyWebApi } from 'spotify-web-api-ts';
import * as settings from '../../../../common/src/settings';
import { Device } from 'spotify-web-api-ts/types/types/SpotifyObjects';
import { isEmpty, noop } from 'lodash';
import StartGame from './StartGame.vue';
import { loadingMessages } from '../model/constants';
import { CreateRound } from '../model/interfaces';

@Component({
  components: {
    StartGame
  }
})
export default class SpotifySettings extends Vue {

  isEmpty = isEmpty;
  loadingDevices = false;
  creatingQuiz = false;
  randomMessage = '';
  randomMessageTimer = -1;
  loadingMessages = [...loadingMessages];

  async mounted() {
    this.pickRandomMessage();
    await store.dispatch('loadUserProfile');
  }

  goBack() {
    this.$emit('go-back');
  }

  async createRound(quizSettings: CreateRound) {
    this.randomMessageTimer = setInterval(() => this.pickRandomMessage(), 3500);
    this.creatingQuiz = true;
    await store.dispatch('createRound', quizSettings).catch(noop); // TODO error handling
    this.creatingQuiz = false;
    clearInterval(this.randomMessageTimer);
    this.$emit('created');
  }

  pickRandomMessage() {
    const length = this.loadingMessages.length;
    if (length === 0) return 'I ran out of funny messages to display :(';
    const random = Math.floor(Math.random() * length);
    this.randomMessage = this.loadingMessages.splice(random, 1)[0];
  }

  deviceSelected() {
    this.$emit('device-selected');
  }

  get spotifyDevices() {
    return store.getters.spotifyDevices as Device[];
  }

  get spotifyDevice() {
    return store.getters.selectedSpotifyDevice as Device | null;
  }

  set spotifyDevice(device: Device | null) {
    if (!device || !device.id) return; 
    store.dispatch('setSpotifyDevice', device.id);
  }

  get playerName() {
    return store.state.currentUserId;
  }

  set playerName(name: string) {
    store.commit('setPlayerName', name);
  }

  get spotifyConnected() {
    return store.getters.spotifyAccessToken;
  }

  async loadDevices() {
    this.loadingDevices = true;
    try {
      await store.dispatch('loadDevices');
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingDevices = false;
    }
  }

  loginToSpotify() {
    const spotify = new SpotifyWebApi({
      redirectUri: settings.callbackUrl,
      clientId: settings.spotifyAppId,
    });

    const url = spotify.getRefreshableAuthorizationUrl({
      scope: ['user-read-private', 'user-read-email', 'streaming', 'user-read-currently-playing','user-modify-playback-state','user-read-playback-state'],
      state: store.state.currentUserId
    })
    window.location.replace(url);
  }
}
