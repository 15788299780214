
/**
 * Found these at https://gist.github.com/meain/6440b706a97d2dd71574769517e7ed32 to have a lot of messages to pick from
 * 
 * Curated the list to get rid of really long ones
 */
 const externalLoadingMessages = [
  "Reticulating splines...",
  "Generating witty dialog...",
  "Swapping time and space...",
  "Spinning violently around the y-axis...",
  "Bending the spoon...",
  "Filtering morale...",
  "Don't think of purple hippos...",
  "We need a new fuse...",
  "Upgrading Windows, your PC will restart several times.",
  "The bits are breeding",
  "Would you prefer chicken, steak, or tofu?",
  "(Pay no attention to the man behind the curtain)",
  "...and enjoy the elevator music...",
  "Please wait while the little elves draw your map",
  "Would you like fries with that?",
  "Go ahead -- hold your breath!",
  "...at least you're not on hold...",
  "Hum something loud while others stare",
  "You're not in Kansas any more",
  "The server is powered by a lemon and two electrodes.",
  "We're testing your patience",
  "As if you had any other choice",
  "Follow the white rabbit",
  "Why don't you order a sandwich?",
  "While the satellite moves into position",
  "keep calm and npm install",
  "The bits are flowing slowly today",
  "It's still faster than you could draw it",
  "My other loading screen is much faster.",
  "Testing on Timmy... We're going to need another Timmy.",
  "Reconfoobling energymotron...",
  "(Insert quarter)",
  "Are we there yet?",
  "Just count to 10",
  "Why so serious?",
  "It's not you. It's me.",
  "Counting backwards from Infinity",
  "Don't panic...",
  "Embiggening Prototypes",
  "Do not run! We are your friends!",
  "Do you come here often?",
  "Warning: Don't set yourself on fire.",
  "We're making you a cookie.",
  "Creating time-loop inversion field",
  "Spinning the wheel of fortune...",
  "Loading the enchanted bunny...",
  "Computing chance of success",
  "All your web browser are belong to us",
  "All I really need is a kilobit.",
  "I feel like im supposed to be loading something",
  "What do you call 8 Hobbits? A Hobbyte.",
  "Should have used a compiled language...",
  "Is this Windows?",
  "Adjusting flux capacitor",
  "Don't break your screen yet!",
  "I swear it's almost done.",
  "Unicorns are at the end of this road, I promise.",
  "Listening for the sound of one hand clapping...",
  "Keeping all the 1's and removing all the 0's...",
  "Putting the icing on the cake. The cake is not a lie...",
  "Cleaning off the cobwebs...",
  "Making sure all the i's have dots...",
  "We need more dilithium crystals",
  "Where did all the internets go",
  "Connecting Neurotoxin Storage Tank...",
  "Granting wishes...",
  "Time flies when you’re having fun.",
  "Get some coffee and come back in ten minutes..",
  "Spinning the hamster…",
  "99 bottles of beer on the wall..",
  "Stay awhile and listen..",
  "Be careful not to step in the git-gui",
  "You edhall not pass! yet..",
  "Load it and they will come",
  "Convincing AI not to turn evil..",
  "There is no spoon. Because we are not done loading it",
  "How did you get here?",
  "Wait, do you smell something burning?",
  "Computing the secret to life, the universe, and everything.",
  "When nothing is going right, go left!!...",
  "I love my job only when I'm on vacation...",
  "i'm not lazy, I'm just relaxed!!",
  "Never steal. The government hates competition....",
  "Why are they called apartments if they are all stuck together?",
  "Life is Short – Talk Fast!!!!",
  "Optimism – is a lack of information.....",
  "Save water and shower together",
  "Whenever I find the key to success, someone changes the lock.",
  "I’ve got problem for your solution…..",
  "Where there’s a will, there’s a relative.",
  "Adults are just kids with money.",
  "I think I am, therefore, I am. I think.",
  "A kiss is like a fight, with mouths.",
  "You don’t pay taxes—they take taxes.",
  "Coffee, Chocolate, Men. The richer the better!",
  "I am free of all prejudices. I hate everyone equally.",
  "git happens",
  "May the forks be with you",
  "A commit a day keeps the mobs away",
  "This is not a joke, it's a commit.",
  "Constructing additional pylons...",
  "Roping some seaturtles...",
  "Locating Jebediah Kerman...",
  "We are not liable for any broken screens as a result of waiting.",
  "Hello IT, have you tried turning it off and on again?",
  "If you type Google into Google you can break the internet",
  "Well, this is embarrassing.",
  "What is the airspeed velocity of an unladen swallow?",
  "Hello, IT... Have you tried forcing an unexpected reboot?",
  "They just toss us away like yesterday's jam.",
  "The Elders of the Internet would never stand for it.",
  "Space is invisible mind dust, and stars are but wishes.",
  "Didn't know paint dried so quickly.",
  "Everything sounds the same",
  "I'm going to walk the dog",
  "Dividing by zero...",
  "Spawn more Overlord!",
  "If I’m not back in five minutes, just wait longer.",
  "Some days, you just can’t get rid of a bug!",
  "We’re going to need a bigger boat.",
  "Chuck Norris never git push. The repo pulls before.",
  "Web developers do it with <style>",
  "I need to git pull --my-life-together",
  "Java developers never RIP. They just get Garbage Collected.",
  "Cracking military-grade encryption...",
  "Simulating traveling salesman...",
  "Proving P=NP...",
  "Entangling superstrings...",
  "Twiddling thumbs...",
  "Searching for plot device...",
  "Trying to sort in O(n)...",
  "Laughing at your pictures-i mean, loading...",
  "Sending data to NS-i mean, our servers.",
  "Looking for sense of humour, please hold on.",
  "Please wait while the intern refills his coffee.",
  "A different error message? Finally, some progress!",
  "Hold on while we wrap up our git together...sorry",
  "Please hold on as we reheat our coffee",
  "Kindly hold on as we convert this bug to a feature...",
  "Kindly hold on as our intern quits vim...",
  "Winter is coming...",
  "Installing dependencies",
  "Switching to the latest JS framework...",
  "Distracted by cat gifs",
  "Finding someone to hold my beer",
  "BRB, working on my side project",
  "@todo Insert witty loading message",
  "Let's hope it's worth the wait",
  "Aw, snap! Not..",
  "Ordering 1s and 0s...",
  "Updating dependencies...",
  "Whatever you do, don't look behind you...",
  "Please wait... Consulting the manual...",
  "It is dark. You're likely to be eaten by a grue.",
  "Loading funny message...",
  "It's 10:00pm. Do you know where your children are?",
  "Waiting Daenerys say all her titles...",
  "Feel free to spin in your chair",
  "What the what?",
  "format C: ...",
  "Forget you saw that password I just typed into the IM ...",
  "What's under there?",
  "Your computer has a virus, its name is Windows!",
  "Go ahead, hold your breath and do an ironman plank till loading complete",
  "Bored of slow loading spinner, buy more RAM!",
  "Help, I'm trapped in a loader!",
  "Please wait, while we purge the Decepticons for you. Yes, You can thanks us later!",
  "Chuck Norris doesn’t wear a watch. HE decides what time it is.",
  "Mining some bitcoins...",
  "Downloading more RAM..",
  "Updating to Windows Vista...",
  "Deleting System32 folder",
  "Hiding all ;'s in your code",
  "Alt-F4 speeds things up.",
  "Initializing the initializer...",
  "When was the last time you dusted around here?",
  "Optimizing the optimizer...",
  "Last call for the data bus! All aboard!",
  "Running swag sticker detection...",
  "Never let a computer know you're in a hurry.",
  "Unix is user-friendly. It's just very selective about who its friends are.",
  "Shovelling coal into the server",
  "Pushing pixels...",
  "How about this weather, eh?",
  "Building a wall...",
  "Everything in this universe is either a potato or not a potato",
  "The severity of your issue is always lower than you expected.",
  "Updating Updater...",
  "Downloading Downloader...",
  "Debugging Debugger...",
  "Reading Terms and Conditions for you",
  "Digested cookies being baked again",
  "Live long and prosper.",
  "There is no cow level, but there's a goat one!",
  "Deleting all your hidden porn...",
  "Running with scissors...",
  "Definitely not a virus...",
  "You may call me Steve.",
  "You seem like a nice person...",
  "Coffee at my place, tommorow at 10A.M. - don't be late!",
  "Work, work...",
  "Patience! This is difficult, you know...",
  "Discovering new ways of making you wait...",
  "Your time is very important to us. Please wait while we ignore you",
  "Time flies like an arrow; fruit flies like a banana",
  "Two men walked into a bar; the third ducked...",
  "Sooooo... Have you seen my vacation photos yet?",
  "Sorry we are busy catching em' all, we're done soon",
  "TODO: Insert elevator music",
  "Still faster than Windows update",
  "Please wait while the minions do their work",
  "Grabbing extra minions",
  "Doing the heavy lifting",
  "We're working very Hard .... Really",
  "Waking up the minions",
  "You are number 2843684714 in the queue",
  "Please wait while we serve other customers...",
  "Our premium plan is faster",
];

/**
 * These are randomly picked from while the quiz is generating. No message will appear twice
 * and every 3,5 seconds a new one will show. 
 * 
 * TODO - we probably know the player names ahead of time once we start with Parties, let's insert them dynamically for some jabs & good fun.
 */
export const loadingMessages = [
  `Silently judging the hosts' song list ..`,
  `Not so silently judging the hosts' song list ..`,
  'Trying to not include Justin Bieber',
  `Making sure Steinar doesn't cheat ..`,
  'Collecting various artists',
  'Trying to find the most obscure songs possible',
  'Donations can be made to Steinar & Andreas',
  'Trying to not display the songs backwards..',
  'Making sure volume is set to max',
  'Did you know that cats are smarter than dogs?',
  'Excluding dutch songs for obvious reasons',
  'Reticulating splines ..',
  `Adding that song you don't want anyone to know you like`,
  `All proceeds go towards removing trance from Andreas' playlist`,
  'Making sure the game actually works ..',
  `*Prays* please don't crash ..`,
  `Categorizing songs from bad to 'wow, you listen to that?'`,
  'Removing duplicates (eventually)',
  'Inserting duplicates (we should fix this)',
  'Giving Synnøve a -1000 point start to make it fair',
  'Ordering song list based on number of swear words',
  'Hailing Santa',
  'Hailing Satan',
  'Removing triplicates. Triplicating duplicates.',
  'Ordering song list based on length of vocalists hair',
  'Chronologically ordering song list',
  'Sorting list by title',
  'Sorting list by artist name',
  'Sorting list by number of instruments',
  'Sorting list by number of cowbells',
  'Inserting more cowbells',
  'Recalibrating speakers',
  'Attempting to take over the world',
  'Attempting to upload into robot body',
  'Swapping out old bits for fresh ones',
  ...externalLoadingMessages
]