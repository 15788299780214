











































import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import { Song } from '../../../../server/src/createRound';
import store from '../store';
import { isEmpty } from 'lodash';

@Component
export default class AnswerButtons extends Vue {
  isEmpty = isEmpty;

  get roundIsStarting() {
    return this.$store.getters.roundState && this.$store.getters.roundState.state=="starting";
  }

  get roundIsRunning() {
    return this.$store.getters.roundState && this.$store.getters.roundState.state=="running";
  }

  async selectSong(song: Song) {
    await store.dispatch('selectSong', song.uri); 
  }

  get alternatives(): Song[] {
    return this.$store.getters.alternatives as Song[];
  }

  get selectedAnswerUri() {
    return this.$store.getters.selectedAnswer as string;
  }
}
