import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueNativeSock from 'vue-native-websocket';
import { wsUrl } from '../../../common/src/settings';
import store from './store';
import VueRouter from 'vue-router';

Vue.config.productionTip = false;
Vue.use(VueNativeSock, `${wsUrl}`,
{ 
  store,
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 5000,
  connectManually: false });
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import ('./views/Home.vue')
  },
  {
    path: '/callback',
    component: () => import ('./views/Callback.vue')
  }
];

export const router = new VueRouter({mode: 'history', routes});

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
