






















































































































































































import Vue from 'vue';
import Component from 'vue-class-component'
import ActiveGame from './components/ActiveGame.vue';
import Players from './components/Players.vue';
import SpotifySettings from './components/SpotifySettings.vue';
import CreateQuiz from './components/StartGame.vue';
import AnswerButtons from './components/AnswerButtons.vue';
import WelcomePage from './components/WelcomePage.vue';
import store from './store';
import { isEmpty, noop } from 'lodash';
import { SpotifyWebApi } from 'spotify-web-api-ts';
import * as settings from '../../../common/src/settings';
import { Device } from 'spotify-web-api-ts/types/types/SpotifyObjects';

@Component({
  components : {
    ActiveGame, CreateQuiz, Players, AnswerButtons, SpotifySettings, WelcomePage
  }
})
export default class App extends Vue {

  private gameid = '';
  private joinGameDialog = false;
  private clientId = 'fd4350cfb21d4d5a8cae985b6018dcb5';
  private showSettings = false;

  private showHostGame = false;
  private showJoinGame = false;
  private showHostDialog = false;

  private _gameCode = '';

  set gameCode(val: string) {
    console.log("Setting", val);
    this._gameCode = val.toUpperCase();
  }

  get gameCode() {
    return this._gameCode;
  }

  get roundIsFinished() {
    return this.$store.getters.roundState && this.$store.getters.roundState.state=="finished";
  }

  get isHost() {
    return this.$store.getters.isHost;
  }

  deviceSelected(device: Device) {
    
  }

  mounted() {
    const uri = window.location.search.substring(1); 
    const params = new URLSearchParams(uri);
    const code = params.get("code");
    console.log("code: " + code);     

    // We can assume this was the callback for now. Let's go directly to showHostGame.
    // We can solve all this cleaner with router api later.
    if (code) {
      this.showHostGame = true;
    }
  }

  doShowHostGame() {
    store.dispatch('createQuiz');
    store.commit('setIsHost', true);
    this.showHostGame = true;
    this.showHostDialog = true;
  }

  doShowJoinGame() {
    this.showJoinGame = true;
    store.commit('setIsHost', false);
    this.$nextTick(() => {
      (this.$refs.codefield as any).focus();
    })
  }

  async doPaste() {
    const text = await navigator.clipboard.readText();
    this.gameCode = text.substring(0, 4);
    this.doJoinQuiz();
  }

  get showWelcome() {
    return isEmpty(this.playerName);
  }

  get playerName() {
    return store.state.currentUserId;
  }

  set playerName(name: string) {
    store.dispatch('setPlayerName', name).catch(noop);
  }

  get quizId() {
    return this.$store.getters.quizId as string | null;
  }

  get roundId() {
    return this.$store.getters.roundId as string | null;
  }

get currentQuizId() {
    return this.quizId ?? 'XXXX';
  }

  get currentRoundId() {
    return this.roundId ?? 'YYYY';
  }

  get readyForNextSong() {
    return this.$store.getters.readyForNextSong;
  }

  async doJoinQuiz() {
    this.gameid = this._gameCode;
    await this.$store.dispatch('joinQuiz', this.gameid); 
  }

  nextSong() {
    this.$store.dispatch('nextSong'); 
  }

  newRound() {
  }

  get hasSpotifyLogin() {
    return !isEmpty(this.$store.getters.spotifyAccessToken);
  }

  get questionProgress() {
    return Math.floor(this.$store.getters.questionProgress/100);
  }

  reset() {
    this.showHostGame = false;
    this.showJoinGame = false;
  }

  loginToSpotify() {
    const spotify = new SpotifyWebApi({
      redirectUri: settings.callbackUrl,
      clientId: this.clientId,
    });

    const url = spotify.getRefreshableAuthorizationUrl({
      scope: ['user-read-private', 'user-read-email', 'streaming', 'user-read-currently-playing','user-modify-playback-state','user-read-playback-state'],
      state: store.state.currentUserId
    })
    window.location.replace(url);
  }
}
