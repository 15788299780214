







































import { noop } from 'lodash';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import { getGenreSeeds } from '../api';
import { CreateRound } from '../model/interfaces';

@Component
export default class StartGame extends Vue {
  @Prop() disabled!: boolean;
  
  private genre = 'rock';
  private choiceCaption = "both";
  private genres: string[] = [];
  private releaseDecade = "any";

  async mounted() {
    this.genres = (await getGenreSeeds(this.spotifyAccessToken).catch(noop)).genres;
  }

  async createRound() {
    const round: CreateRound =  { genre: this.genre, choiceCaption: this.choiceCaption, releaseDecade: this.releaseDecade };
    this.$emit('create-round',  round);
  }

  async cancel() {
    this.$emit("cancel");
  }

  get spotifyAccessToken() {
    return this.$store.getters.spotifyAccessToken as string;
  }
}

