"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.callbackUrl = exports.wsNotifyEndpoint = exports.wsUrl = exports.urlprefix = exports.spotifyAppId = void 0;
exports.spotifyAppId = "fd4350cfb21d4d5a8cae985b6018dcb5";
// export const urlprefix = "http://localhost:3000/dev";
//  export const wsUrl = "ws://localhost:3001";
//  export const wsNotifyEndpoint = "http://localhost:3001";
//  export const callbackUrl = "http://localhost:8080/callback/";
exports.urlprefix = "https://5syupvfnvl.execute-api.eu-north-1.amazonaws.com";
exports.wsUrl = "wss://nd1udu4g79.execute-api.eu-north-1.amazonaws.com/dev";
exports.wsNotifyEndpoint = "https://nd1udu4g79.execute-api.eu-north-1.amazonaws.com/dev";
exports.callbackUrl = "https://tuneparty.tv/callback/";
