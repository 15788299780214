




import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import { SpotifyWebApi } from 'spotify-web-api-ts';
import { Device } from 'spotify-web-api-ts/types/types/SpotifyObjects';
import { DeviceIdOptions, PlayOptions, SearchOptions } from 'spotify-web-api-ts/types/types/SpotifyOptions';
import store from '../store';
import * as settings from '../../../../common/src/settings';

@Component
export default class ActiveGame extends Vue {
    private clientId = 'fd4350cfb21d4d5a8cae985b6018dcb5';
    private clientSecret = '9e4c8efa325f429491b2ef8750309199';
    private redirectUri = settings.callbackUrl;

    private devices:Device[] = [];

    mounted() {
    }

    loginToSpotify() {
        const spotify = new SpotifyWebApi({
            redirectUri: this.redirectUri,
            clientId: this.clientId,
        });

        const url = spotify.getRefreshableAuthorizationUrl({
            scope: ['user-read-private', 'user-read-email', 'streaming', 'user-read-currently-playing','user-modify-playback-state','user-read-playback-state'],
            state: "my_state"
        })
        window.location.replace(url);
    }

    async listDevices() {
      var credentials = {
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          redirectUri: settings.callbackUrl
      };
      var spotifyApi = new SpotifyWebApi(credentials);
      console.log("Using token: ", store.state.spotifyAccessToken);
      spotifyApi.setAccessToken(store.state.spotifyAccessToken);

      let devices:any = await spotifyApi.player.getMyDevices();

      console.log(devices.devices);
      devices.devices.forEach((device: any) => {
          this.devices.push(device);
      })
    }

    async pauseDevice() {
        const id = "1d72f661d8bd92ec23c6cbeed96df7db427103b2";
        var credentials = {
            clientId: this.clientId,
            clientSecret: this.clientSecret,
            redirectUri: settings.callbackUrl
        };
        var spotifyApi = new SpotifyWebApi(credentials);
        spotifyApi.setAccessToken(store.state.spotifyAccessToken);
        let options: DeviceIdOptions = {
            device_id : id
        }
        spotifyApi.player.play(options);

        let cats = await spotifyApi.browse.getCategories();
        console.log("Categories",cats);

        let rock = await spotifyApi.browse.getCategoryPlaylists("rock");
        console.log("rock",rock);

        let searchOptions: SearchOptions = {

        }
        let search = await spotifyApi.search.searchTracks("year:1970-1980,category:rock");
        console.log("search",search);
        let albumId = search.items[0].album.id;
        let album = await spotifyApi.albums.getAlbum(albumId);
        console.log(album);
        let track = album.tracks.items[0];
        console.log(track);

        let playOptions: PlayOptions = {
            device_id: id,
            uris: [track.uri]
        }
        await spotifyApi.player.play(playOptions);
        await spotifyApi.player.seek(40000);
    }
}
